import { AUTO_ASSIGN_AGGREGATE } from "../../constants/auto-assign"

const state = () => ({
  aggregate: {},
})

const mutations = {
  setAggregateAutoAssignStatus(state, data) {
    state.aggregate = data
  },
}

const getters = {
  getAggregateAutoAssignStatus: (state) => (type, aggregate_id) => {
    const aggregate_constant = AUTO_ASSIGN_AGGREGATE[type]
    const aggregate_data = state.aggregate[aggregate_constant.field] || []
    const data = aggregate_data.find(
      (item) => item[aggregate_constant.id_field] === aggregate_id
    )
    return {
      ...data,
      ...aggregate_constant,
    }
  },
}

export default { state, mutations, getters }
