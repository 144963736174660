import {
  AUTO_ASSIGN_CHAT_INFO,
  AUTO_ASSIGN_INTERNAL_MESSAGE_INFO,
} from "../constants/auto-assign"
import { AUTO_ASSIGN_TYPES } from "../constants/contact"

const autoAssignHelper = {
  chatInfo: (action_type) => {
    return AUTO_ASSIGN_CHAT_INFO[action_type] || {}
  },
  internalMessageInfo: (action_type) => {
    return AUTO_ASSIGN_INTERNAL_MESSAGE_INFO[action_type] || {}
  },
  isAutoAssign: (event) => {
    const auto_assign_types = Object.keys(AUTO_ASSIGN_TYPES)
    const is_assign_type = auto_assign_types.includes(event.assign_type)
    const deprecated_is_auto_assign_flag = event.is_auto_assign
    return is_assign_type || deprecated_is_auto_assign_flag
  }
}

export default ({ app }, inject) => {
  inject("autoAssignHelper", autoAssignHelper)
}
