import _ from "lodash"

const state = () => ({
  show_contact_profile_dialog: false,
  has_some_contact_profile_field_updated: false,
  routeNextFn: null,
  auto_detect_data: {},
})

const mutations = {
  setShowContactProfileDialog(state, boolean) {
    state.show_contact_profile_dialog = boolean
  },
  setHasSomeContactProfileFieldUpdated(state, boolean) {
    state.has_some_contact_profile_field_updated = boolean
  },
  setContactProfileRouteNextFn(state, data = null) {
    state.routeNextFn = data
  },
  setContactProfileAutoDetectData(state, data) {
    state.auto_detect_data = data
  },
  resetContactProfileAutoDetectData(state) {
    state.auto_detect_data = {}
  },
}

const actions = {
  handleBeforeLeaveEditContactProfile({ state, commit }, { routeNextFn }) {
    const has_some_updated = _.get(
      state,
      "has_some_contact_profile_field_updated"
    )

    if (has_some_updated) {
      commit("setShowContactProfileDialog", true)
      commit("setContactProfileRouteNextFn", routeNextFn)
    } else {
      routeNextFn()
    }
  },
}

export default { state, mutations, actions }
