
import _ from "lodash"

const auto_assign_chat_status_icon = require("~/assets/images/icon-auto-assign-chat-status.svg")
const case_status_icon = require("~/assets/images/case_start_icon_outline_16px.svg")
const end_case_status_icon = require("~/assets/images/case_end_icon_outline_16px.svg")
const event_code_template = [
  {
    event_code: "chat_assignment.assign_bot_to_request_by_contact",
    text: "<b>เรียกสมาชิก</b>",
    style: 1,
    type: "default",
    actor: null,
    actor_team: null,
    event_icon_image_field: "",
  },
  {
    event_code: "chat_assignment.assign_bot_to_request_by_inbox",
    text: "<b>เรียกสมาชิก</b>",
    style: 1,
    type: "default",
    actor: null,
    actor_team: null,
    event_icon_image_field: "",
  },
  {
    event_code: "chat_assignment.assign_bot_to_request_by_bot_inbox",
    text: "<b>เรียกสมาชิก</b>",
    style: 1,
    type: "default",
    actor: null,
    actor_team: null,
    event_icon_image_field: "",
  },
  {
    event_code: "chat_assignment.assign_bot_to_agent_by_contact",
    style: 5,
    type: "assign_chat",
    actor: null,
    actor_team: null,
    event_icon_image_field: "assign_to_member_id",
  },
  {
    event_code: "chat_assignment.assign_bot_to_agent_by_member",
    style: 5,
    type: "assign_chat",
    actor: "assign_by",
    actor_team: "assign_by",
    event_icon_image_field: "",
  },
  {
    event_code: "chat_assignment.assign_bot_to_me_by_member",
    style: 4,
    type: "accept_chat",
    actor: "assign_to",
    actor_team: "assign_to",
    event_icon_image_field: "assign_to_member_id",
  },
  {
    event_code: "chat_assignment.assign_request_to_bot_by_member",
    style: 4,
    type: "end_chat",
    actor: "assign_by",
    actor_team: "assign_by",
    event_icon_image_field: "assign_by_member_id",
  },
  {
    event_code: "chat_assignment.assign_request_to_agent_by_member",
    style: 5,
    type: "assign_chat",
    actor: "assign_by",
    actor_team: "assign_by",
    event_icon_image_field: "",
  },
  {
    event_code: "chat_assignment.assign_request_to_me_by_member",
    style: 4,
    type: "accept_chat",
    actor: "assign_by",
    actor_team: "assign_by",
    event_icon_image_field: "assign_by_member_id",
  },
  {
    event_code: "chat_assignment.assign_agent_to_bot_by_member",
    style: 4,
    type: "end_chat",
    actor: "assign_by",
    actor_team: "assign_by",
    event_icon_image_field: "assign_by_member_id",
  },
  {
    event_code: "chat_assignment.assign_agent_to_agent_by_member",
    style: 5,
    type: "assign_chat",
    actor: "assign_by",
    actor_team: "assign_by",
    event_icon_image_field: "",
  },
  {
    event_code: "chat_assignment.assign_agent_to_me_by_member",
    style: 4,
    type: "accept_chat",
    actor: "assign_by",
    actor_team: "assign_by",
    event_icon_image_field: "assign_by_member_id",
  },
  {
    event_code: "chat_assignment.assign_me_to_bot_by_member",
    style: 4,
    type: "end_chat",
    actor: "assign_by",
    actor_team: "assign_by",
    event_icon_image_field: "assign_by_member_id",
  },
  {
    event_code: "chat_assignment.assign_me_to_agent_by_member",
    style: 5,
    type: "assign_chat",
    actor: "assign_by",
    actor_team: "assign_by",
    event_icon_image_field: "",
  },
  {
    event_code: "chat_assignment.accept_assign_bot_to_agent_by_member",
    style: 4,
    type: "accept_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "chat_assignment.accept_assign_bot_to_me_by_member",
    style: 4,
    type: "accept_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "chat_assignment.accept_assign_request_to_agent_by_member",
    style: 4,
    type: "accept_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "chat_assignment.accept_assign_request_to_me_by_member",
    style: 4,
    type: "accept_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "chat_assignment.accept_assign_agent_to_agent_by_member",
    style: 4,
    type: "accept_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "assign_to_member_id",
  },
  {
    event_code: "chat_assignment.accept_assign_agent_to_me_by_member",
    style: 4,
    type: "accept_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "chat_assignment.reject_assign_bot_to_agent_by_member",
    style: 4,
    type: "reject_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "chat_assignment.reject_assign_bot_to_me_by_member",
    style: 4,
    type: "reject_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "chat_assignment.reject_assign_request_to_agent_by_member",
    style: 4,
    type: "reject_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "chat_assignment.reject_assign_request_to_me_by_member",
    style: 4,
    type: "reject_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "chat_assignment.reject_assign_agent_to_agent_by_member",
    style: 4,
    type: "reject_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "chat_assignment.reject_assign_agent_to_me_by_member",
    style: 4,
    type: "reject_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "chat_assignment.cancel_assign_bot_to_agent_by_member",
    style: 4,
    type: "cancel_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "chat_assignment.cancel_assign_bot_to_me_by_member",
    style: 4,
    type: "cancel_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "chat_assignment.cancel_assign_request_to_agent_by_member",
    style: 4,
    type: "cancel_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "chat_assignment.cancel_assign_request_to_me_by_member",
    style: 4,
    type: "cancel_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "chat_assignment.cancel_assign_agent_to_agent_by_member",
    style: 4,
    type: "cancel_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "chat_assignment.cancel_assign_agent_to_me_by_member",
    style: 4,
    type: "cancel_chat",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "chat.send_first_message_by_member",
    style: 4,
    type: "first_response",
    actor: "action_by",
    actor_team: "action_by",
    event_icon_image_field: "action_by_member_id",
  },
  {
    event_code: "case.open",
    text: "<b>เริ่มเคส</b>",
    style: 6,
    type: "default",
    actor: null,
    actor_team: null,
    event_icon_image_field: "",
  },
  {
    event_code: "case.close",
    text: "<b>ปิดเคส</b>",
    style: 7,
    type: "default",
    actor: null,
    actor_team: null,
    event_icon_image_field: "",
  },
  {
    event_code: "case.comment.created",
    style: 3,
    type: "comment",
    actor: null,
    actor_team: null,
    event_icon_image_field: "",
  },
  {
    event_code: "chat_assignment.assign_bot_to_agent_by_contactAction",
    style: 5,
    type: "assign_chat",
    actor: null,
    actor_team: null,
    event_icon_image_field: "assign_to_member_id",
  },
  {
    event_code: "assign_to_member",
    style: 5,
    type: "assign_chat",
    actor: null,
    actor_team: null,
    event_icon_image_field: "",
  },
  {
    event_code: "sale_owner_assign_to_member",
    style: 5,
    type: "assign_chat",
    actor: null,
    actor_team: null,
    event_icon_image_field: "",
  },
]

const event_style_template = [
  {
    id: 1,
    color: "#E64949",
    bg_color: "#FFEEEE",
    border_color: "#FFEEEE",
    is_profile: false,
    icon: "account_circle",
    icon_class: "material-icons",
  },
  {
    id: 2,
    color: "#292929",
    bg_color: "#ffffff",
    border_color: "#757575",
    is_profile: false,
    icon: "sticky_note_2",
    icon_class: "material-icons-outlined",
  },
  {
    id: 3,
    color: "#292929",
    bg_color: "#ffffff",
    border_color: "#757575",
    is_profile: false,
    icon: "sticky_note_2",
    icon_class: "material-icons-outlined",
  },
  {
    id: 4,
    color: "#292929",
    bg_color: "#ffffff",
    border_color: "#757575",
    is_profile: true,
  },
  {
    id: 5,
    color: "#292929",
    bg_color: "#ffffff",
    border_color: "#757575",
    is_profile: false,
    icon: "arrow_forward",
    icon_class: "material-icons-outlined",
  },
  {
    id: 6,
    color: "#292929",
    bg_color: "#ffffff",
    border_color: "#757575",
    is_profile: false,
    is_image: true,
    icon_image: case_status_icon,
    alt: "case icon",
  },
  {
    id: 7,
    color: "#292929",
    bg_color: "#ffffff",
    border_color: "#757575",
    is_profile: false,
    is_image: true,
    icon_image: end_case_status_icon,
    alt: "end case icon",
  },
  {
    id: 8,
    color: "#292929",
    bg_color: "#ffffff",
    border_color: "#757575",
    is_profile: false,
    is_image: true,
    icon_image: auto_assign_chat_status_icon,
    alt: "auto assign icon",
  },
]

export default {
  props: {
    activities: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    activity_list() {
      return _.filter(this.activities, (item) => {
        if (
          item.event_code !== "case.tags.add" &&
          item.event_code !== "case.tags.remove"
        ) {
          return item
        }
      })
    },
  },
  methods: {
    transformActivity(activity) {
      let template = {}

      template = _.find(event_code_template, {
        event_code: activity.event_code,
      })

      const style = _.find(event_style_template, { id: template?.style }) || {}

      return {
        data: activity,
        content_text: template?.text,
        type: template?.type || "default",
        actor: template?.actor || null,
        actor_team: template?.actor_team || null,
        event_icon_image_field: template?.event_icon_image_field,
        style: style,
      }
    },
  },
}
