import auto_assign_team_icon from "~/assets/images/icon-auto-assign-team.svg"
import auto_assign_dark_icon from "~/assets/images/auto_icon_dark.svg"
import automation_default_icon from "~/assets/images/menu_icon_auto_outline_20px.svg"

export const AUTO_ASSIGN_AGGREGATE = {
  chat_status: {
    type: "chat_status",
    field: "auto_assign_chat_status",
    id_field: "chat_status",
    setting_link: "/automation/auto-assign-chat-status",
  },
  team: {
    type: "team",
    field: "auto_assign_team",
    id_field: "team_id",
    setting_link: "/automation/auto-assign-team",
  },
}

export const AUTO_ASSIGN_CHAT_INFO = {
  automation_by_team: {
    type: "assign_by_team",
    chat_label_actor: "ออโตเมชัน",
    event_actor: "ส่งแชทตามทีม",
    action_text: "ให้",
    preview_message: "ส่งแชทอัตโนมัติ",
    chat_label_icon: automation_default_icon,
    event_icon: auto_assign_team_icon,
  },
  automation_by_chat_status: {
    type: "assign_by_team",
    chat_label_actor: "ออโตเมชัน",
    event_actor: "ส่งแชทตามสถานะแชท",
    action_text: "ให้",
    preview_message: "ส่งแชทอัตโนมัติ",
    chat_label_icon: automation_default_icon,
    event_icon: auto_assign_team_icon,
  },
  automation: {
    type: "sale_owner_assign_to_member",
    chat_label_actor: "ระบบอัตโนมัติ",
    event_actor: "ระบบอัตโนมัติ",
    action_text: "ส่งแชทให้",
    preview_message: "ส่งแชทอัตโนมัติ",
    chat_label_icon: automation_default_icon,
    event_icon: auto_assign_dark_icon,
  },
  default: {
    type: "assign_by_team",
    chat_label_actor: "ออโตเมชัน",
    event_actor: "ส่งแชทตามทีม",
    action_text: "ให้",
    preview_message: "ส่งแชทอัตโนมัติ",
    chat_label_icon: automation_default_icon,
    event_icon: auto_assign_team_icon,
  },
}

export const AUTO_ASSIGN_INTERNAL_MESSAGE_INFO = {
  chat_status_assign_to_team: {
    type: "assign_by_chat_status",
    event_actor: "ส่งแชทตามสถานะแชท",
    action_text: "ส่งแชทให้",
    event_icon: auto_assign_team_icon,
    error_text: null,
    setting_link: "/automation/auto-assign-chat-status",
  },
  chat_status_assign_to_fallback_owner: {
    type: "assign_by_chat_status",
    event_actor: "ส่งแชทตามสถานะแชท",
    action_text: "จึงส่งแชทไปที่ทีม",
    event_icon: auto_assign_team_icon,
    error_text: "ไม่พบทีมที่ตั้งค่าไว้",
    setting_link: "/automation/auto-assign-chat-status",
  },
  team_assign_to_member: {
    type: "assign_by_team",
    event_actor: "ส่งแชทตามทีม",
    action_text: "ส่งแชทให้",
    event_icon: auto_assign_team_icon,
    error_text: null,
    setting_link: "/automation/auto-assign-team",
  },
  team_assign_to_sale_owner: {
    type: "assign_by_team",
    event_actor: "ส่งแชทตามทีม",
    action_text: "ส่งแชทให้",
    event_icon: auto_assign_team_icon,
    error_text: null,
    setting_link: "/automation/auto-assign-team",
  },
  team_no_online_member_assign_to_member: {
    type: "assign_by_team",
    event_actor: "ส่งแชทตามทีม",
    action_text: "ส่งแชทให้",
    event_icon: auto_assign_team_icon,
    error_text:
      "ไม่พบสมาชิกที่ออนไลน์ในขณะนี้ จึงส่งเรียงตามชื่อสมาชิกโดยไม่สนใจเงื่อนไข",
    setting_link: "/automation/auto-assign-team",
  },
  team_sale_owner_offline_assign_to_member: {
    type: "assign_by_team",
    event_actor: "ส่งแชทตามทีม",
    action_text: "ส่งแชทให้",
    event_icon: auto_assign_team_icon,
    error_text:
      "พบว่าพนักงานขายที่ดูแลออฟไลน์อยู่ จึงส่งเรียงตามชื่อสมาชิกโดยไม่สนใจเงื่อนไข",
    setting_link: "/automation/auto-assign-team",
  },
  team_no_sale_owner_assign_to_member: {
    type: "assign_by_team",
    event_actor: "ส่งแชทตามทีม",
    action_text: "ส่งแชทให้",
    event_icon: auto_assign_team_icon,
    error_text:
      "ไม่พบพนักงานขายที่ดูแล จึงส่งเรียงตามชื่อสมาชิกโดยไม่สนใจเงื่อนไข",
    setting_link: "/automation/auto-assign-team",
  },
  team_no_online_member_assign_to_team: {
    type: "assign_by_team",
    event_actor: "ส่งแชทตามทีม",
    action_text: "จึงส่งแชทกลับไปที่ทีม",
    event_icon: auto_assign_team_icon,
    error_text: "ไม่พบสมาชิกที่ออนไลน์ในขณะนี้",
    setting_link: "/automation/auto-assign-team",
  },
  team_sale_owner_offline_assign_to_team: {
    type: "assign_by_team",
    event_actor: "ส่งแชทตามทีม",
    action_text: "จึงส่งแชทกลับไปที่ทีม",
    event_icon: auto_assign_team_icon,
    error_text: "พบว่าพนักงานขายที่ดูแลออฟไลน์อยู่",
    setting_link: "/automation/auto-assign-team",
  },
  team_no_sale_owner_assign_to_team: {
    type: "assign_by_team",
    event_actor: "ส่งแชทตามทีม",
    action_text: "จึงส่งแชทกลับไปที่ทีม",
    event_icon: auto_assign_team_icon,
    error_text: "ไม่พบพนักงานขายที่ดูแล",
    setting_link: "/automation/auto-assign-team",
  },
  team_no_member_assign_to_team: {
    type: "assign_by_team",
    event_actor: "ส่งแชทตามทีม",
    action_text: "จึงส่งแชทกลับไปที่ทีม",
    event_icon: auto_assign_team_icon,
    error_text: "ไม่พบสมาชิกในทีม (0 คน)",
    setting_link: "/automation/auto-assign-team",
  },
  team_only_assignee_member_assign_to_team: {
    type: "assign_by_team",
    event_actor: "ส่งแชทตามทีม",
    action_text: "จึงส่งแชทกลับไปที่ทีม",
    event_icon: auto_assign_team_icon,
    error_text: "ไม่พบสมาชิกคนอื่นในทีมนอกจากผู้รับผิดชอบ",
    setting_link: "/automation/auto-assign-team",
  },
}
