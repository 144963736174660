
import _ from "lodash"

export default {
  props: {
    activity: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    activity_type() {
      return _.get(this.activity, "type")
    },
    content() {
      return _.get(this.activity, "content_text")
    },
    assign_by_member() {
      return _.get(this.activity, "data.assign_by_member_id")
    },
    assign_by_team() {
      return _.get(this.activity, "data.assign_by_team_id")
    },
    assign_to_member() {
      return _.get(this.activity, "data.assign_to_member_id")
    },
    assign_to_team() {
      return _.get(this.activity, "data.assign_to_team_id")
    },
    action_by_member() {
      return _.get(this.activity, "data.action_by_member_id")
    },
    action_by_team() {
      return _.get(this.activity, "data.action_by_team_id")
    },
    actor() {
      return _.get(this.activity, "actor")
    },
    actor_team() {
      return _.get(this.activity, "actor_team")
    },
    assign_to_team_id() {
      return _.get(this.assign_to_team, "_id")
    },
    action_by_team_id() {
      return _.get(this.action_by_team, "_id")
    },
    actor_member_info() {
      if (this.actor === "assign_by") {
        return this.assign_by_member
      } else if (this.actor === "action_by") {
        return this.action_by_member
      } else if (this.actor === "assign_to") {
        return this.assign_to_member
      } else {
        return null
      }
    },
    actor_team_info() {
      if (this.actor_team === "assign_by") {
        return this.assign_by_team
      } else if (this.actor_team === "action_by") {
        return this.action_by_team
      } else if (this.actor_team === "assign_to") {
        return this.assign_to_team
      } else {
        return null
      }
    },
    assign_type() {
      return _.get(this.activity, "data.assign_type")
    },
    auto_assign_info() {
      return this.$autoAssignHelper.chatInfo(this.assign_type)
    },
    is_auto_assign() {
      return this.$autoAssignHelper.isAutoAssign(this.activity.data)
    },
  },
}
