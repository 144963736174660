
import { mapGetters } from "vuex"
import auto_assign_active_icon_src from "~/assets/images/auto_assign_active_icon.svg"
import auto_assign_deactive_icon_src from "~/assets/images/auto_assign_deactive_icon.svg"

export default {
  props: {
    type: {
      type: String,
      default: "", // "chat_status" | "team"
    },
    aggregate_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      auto_assign_active_icon: auto_assign_active_icon_src,
      auto_assign_deactive_icon: auto_assign_deactive_icon_src,
    }
  },
  computed: {
    ...mapGetters(["getAggregateAutoAssignStatus", "base_path"]),
    badge_state() {
      const auto_assign_status =
        this.getAggregateAutoAssignStatus(this.type, this.aggregate_id) || {}

      return {
        icon_src: auto_assign_status.is_enabled
          ? this.auto_assign_active_icon
          : this.auto_assign_deactive_icon,
        tooltip_content: auto_assign_status.is_enabled
          ? "ออโตเมชัน เปิดใช้งาน"
          : "ออโตเมชัน ปิดใช้งาน",
        badge_classes: auto_assign_status.is_enabled ? "is-active" : "",
        setting_link:
          `${this.base_path}${auto_assign_status.setting_link}` || "",
      }
    },
  },
}
