import moment from "moment"

const buddhistYearConverter = (moment, format) => {
  const christianYear = moment.format("YYYY")
  const buddhistYear = (parseInt(christianYear) + 543).toString()
  return moment
    .format(
      format
        .replace("YYYY", buddhistYear)
        .replace("YY", buddhistYear.substring(2, 4))
    )
    .replace(christianYear, buddhistYear)
}

const isToday = (date) => {
  let today = moment().startOf("day")
  return moment(date).isSame(today, "d")
}
const isYesterday = (date) => {
  let yesterday = moment().subtract(1, "days").startOf("day")
  return moment(date).isSame(yesterday, "d")
}
// 6 days prior refers to a specific day 6 days before the current day
const isWithinSixDaysPrior = (date) => {
  let seven_days_ago_from_today = moment().subtract(7, "days").endOf("day")

  return moment(date).isAfter(seven_days_ago_from_today, "day")
}
const isInTheSameYear = (date) => {
  return moment().isSame(date, "year")
}
const formatMinuteHourDiffTime = (diff) => {
  return moment.utc(diff).format("mm:ss")
}

const dateFormat = {
  // old: 3/03/64 18:26 -> new : 3 มี.ค. 64 18:26
  dateTime: (date) => {
    return buddhistYearConverter(moment(date), "D MMM YY \xa0HH:mm")
  },

  // old: 3/03/64 -> new : 3 มี.ค. 64
  date: (date) => {
    return buddhistYearConverter(moment(date), "D MMM YY")
  },

  // 18:26
  time: (date) => {
    return moment(date).format("HH:mm")
  },

  // old: 3/03/64 -> new : 3 มี.ค. 64
  fullDateMonth: (date) => {
    return buddhistYearConverter(moment(date), "D MMM YY")
  },

  // old: 3/03/2564 -> new :3 มี.ค. 2564
  fullDateMonthYear: (date) => {
    return buddhistYearConverter(moment(date), "D MMM YYYY")
  },

  // if longer than 7 days:
  //   20/03/64
  // else:
  //   3 นาที
  fromNow: (date) => {
    return moment().diff(date, "days") > 7
      ? buddhistYearConverter(moment(date), "D MMM YY")
      : moment(date).fromNow(true)
  },

  differentFromNowInHours: (date) => {
    const now = moment()
    const compareDate = moment(date)
    return now.diff(compareDate, "hours")
  },

  differentFromNowInYears: (date) => {
    const now = moment()
    const compareDate = moment(date)
    return now.diff(compareDate, "years")
  },

  // old: 4/10/65 | 10:29 -> new : 4 ต.ค. 65 | 10:29
  dateTimeWithDivider: (date) => {
    return buddhistYearConverter(moment(date), "D MMM YY | HH:mm")
  },

  // 12:35
  minuteHourDiffTime: (diff) => {
    return formatMinuteHourDiffTime(diff)
  },

  // 3:05:28
  fullDiffTime: (diff) => {
    return moment.utc(diff).format("H:mm:ss")
  },

  // 3:05:28
  millisecondToTime: (millisecondDuration) => {
    let seconds = moment.duration(millisecondDuration).seconds()
    seconds = `${seconds}`.padStart(2, "0")
    let minutes = moment.duration(millisecondDuration).minutes()
    minutes = `${minutes}`.padStart(2, "0")
    const hours = moment.duration(millisecondDuration).hours()
    const days = Math.trunc(moment.duration(millisecondDuration).asDays())

    if (days > 0) {
      return `${days} วัน ${hours} ชม. ${seconds} น.`
    } else {
      return `${hours}:${minutes}:${seconds}`
    }
  },
  isToday,
  isYesterday,
  isWithinSixDaysPrior,
  isInTheSameYear,
  displaySpecificTime: (date) => {
    if (isToday(date)) {
      return moment(date).format("HH:mm")
    } else if (isYesterday(date)) {
      return "เมื่อวาน"
    } else if (isWithinSixDaysPrior(date)) {
      return `วัน${moment(date).format("dddd")}`
    } else if (!isWithinSixDaysPrior(date) && isInTheSameYear(date)) {
      return moment(date).format("D MMM")
    } else if (!isInTheSameYear(date)) {
      return buddhistYearConverter(moment(date), "D MMM YY")
    }
  },
  formatCountdownTime: (time) => {
    const hours = Math.floor(moment.duration(time).asHours())

    if (hours > 0) {
      const time = formatMinuteHourDiffTime(time)
      return `${hours}:${time}`
    } else {
      return formatMinuteHourDiffTime(time)
    }
  },
}

export default ({ app }, inject) => {
  inject("formatDate", dateFormat)
}
